/**
 * These theme values will override the base theme.
 *
 * We're passing these values to Layout component;
 * The Layout component merges these values with the
 * base theme.
 *
 */

export default {
  colors: {
    // previous background: `#27272A`, 
    // background: `#151718`,
    footerBg: `transparent`
  }
}
